<template>
  <div class="video-container">
    <div class="video-sidebar">
      <el-tree :data="cameraData" :props="defaultProps" class="videoTree" highlight-current default-expand-all
        :render-content="renderTreeContent" @node-click="handleNodeClick"></el-tree>
    </div>
    <div class="video-content" :class="{ fullscreen: fullscreen }">
      <div class="video-operation-header">
        <div class="video-layout-type">
          窗口排列
          <div class="icon-layout" @click="onLayoutChange('1x1')">
            <svg-icon iconClass="layout1"></svg-icon>
          </div>
          <div class="icon-layout" @click="onLayoutChange('2x2')">
            <svg-icon iconClass="layout4"></svg-icon>
          </div>
          <div class="icon-layout" @click="onLayoutChange('3x3')">
            <svg-icon iconClass="layout9"></svg-icon>
          </div>
        </div>
        <div @click="fullscreen = !fullscreen">
          <svg-icon :iconClass="fullscreen ? 'fullscreencancel' : 'fullscreen'" class="icon-fullscreen"></svg-icon>
        </div>
      </div>
      <div class="video-windows">
        <div class="item-row" v-for="(item, row) in videoUrlList" :key="row">
          <div class="item-col" v-for="(elem, col) in item" :key="col">
            <div class="video-item-box" :class="{ active: activeIndex == row * colCount + col }"
              @click="onVideoClick(row, col)">
              <div v-if="!elem.url" class="video-item-empty">
                请选择需要查看的摄像头
              </div>
              <div v-else style="width: 100%; height: 100%">
                <div class="video-item-title">
                  {{ elem.name }}
                </div>
                <camera-video :id="'video' + col + row" :url="elem.url"></camera-video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CameraVideo from "@/components/cameravideo";
import { getDeviceList, UpdateVideoPlay } from "@/api/device";
export default {
  data() {
    return {
      cameraData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      rowcol: "1x1",
      activeIndex: 0,
      activeRow: 0,
      activeCol: 0,
      videoUrlList: [],

      fullscreen: false,

    };
  },
  computed: {
    rowCount() {
      if (this.rowcol) {
        let arr = this.rowcol.split("x");
        if (arr.length == 2) {
          return arr[0] * 1;
        }
      }
      return 1;
    },
    colCount() {
      if (this.rowcol) {
        let arr = this.rowcol.split("x");
        if (arr.length == 2) {
          return arr[1] * 1;
        }
      }
      return 1;
    },
  },
  components: {
    CameraVideo,
  },
  mounted() {
    this.videoUrlList = [];
    this.onLayoutChange("1x1");
    getDeviceList(1, 1000, {
      devicetype: "视频设备",
    }).then((res) => {
      if (res.code == 0) {
        let data = res.data.data.map((item) => {
          item.label = item.DeviceName;
          item.icon = "camera";
          return item;
        });
        this.cameraData = [
          {
            label: "视频监控",
            icon: "video",
            children: data,
          },
        ];

        this.handleNodeClick(data[0]);
        this.refreshVideoData();
      }
    });
  },
  beforeDestroy() {

  },
  methods: {
    renderTreeContent(h, options) {
      // console.log(h, options);
      return h("div", [
        this.$createElement("svg-icon", {
          class: {
            "tree-node-icon": true,
          },
          attrs: {
            iconClass: options.data.icon,
          },
        }),
        this.$createElement(
          "span",
          {
            class: {
              "tree-node-text": true,
            },
          },
          options.data.label
        ),
      ]);
    },
    handleNodeClick(data, node, e) {
      this.videoUrlList[this.activeRow][this.activeCol] = {
        name: data.DeviceName,
        url: data.VideoUrl,
        data: data
      }
      this.$set(this.videoUrlList, this.activeRow, this.videoUrlList[this.activeRow])

    },
    onLayoutChange(layout) {
      this.rowcol = layout;
      this.activeIndex = "";
      let listdata = [];
      let that = this;
      let getOldData = function (row, col) {
        if (that.videoUrlList.length > row) {
          if (that.videoUrlList[row].length > col) {
            return that.videoUrlList[row][col];
          }
        }
        return null;
      }
      for (let index = 0; index < this.rowCount; index++) {
        let rowdata = [];
        for (let elem = 0; elem < this.colCount; elem++) {
          let olddata = getOldData(index, elem);
          rowdata.push(olddata ? olddata : {
            name: "",
            url: "",
            data: "",
          });
        }
        listdata.push(rowdata);
      }
      this.videoUrlList = listdata;
    },
    onVideoClick(row, col) {
      this.activeIndex = row * this.colCount + col;
      this.activeRow = row;
      this.activeCol = col;
    },
    getVideo(row, col) {
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  height: calc(100vh - 96px);
  box-sizing: border-box;
  margin: -20px;
  display: flex;
  align-items: stretch;
  overflow: hidden;

  .video-sidebar {
    width: 240px;
    border-right: 1px solid #2696c2;
  }

  .video-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.video-content.fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #053e81;
  z-index: 10000;
}

.video-operation-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #2696c2;
  padding: 10px;
  color: #20fefd;

  .video-layout-type {
    flex: 1;
  }

  .icon-layout {
    font-size: 18px;
    color: #20fefd;
    margin-left: 10px;
    display: inline-block;
    cursor: pointer;
  }

  .icon-fullscreen {
    font-size: 20px;
    cursor: pointer;
  }
}

.videoTree {
  background: transparent;
  color: #fff;

  ::v-deep {
    .el-tree-node.is-current>.el-tree-node__content {
      background: linear-gradient(270deg, #20fefd, #3959ff);
    }

    .el-tree-node__content {
      height: 36px;
    }

    .el-tree-node__content:hover {
      background-color: rgba(80, 252, 252, 0.1);
    }

    .tree-node-icon {
      font-size: 14px;
      margin-right: 3px;
      display: inline-block;
      vertical-align: middle;
      margin-top: -4px;
    }
  }
}

.video-windows {
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  .item-row {
    display: flex;
    align-items: stretch;
    flex: 1;
  }

  .item-col {
    flex: 1;
  }
}

.video-item-box {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(61, 217, 216, 0.2);
  position: relative;

  &.active {
    border-color: #f00;
  }
}

.video-item-title {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 40px;
  background: rgba(200, 200, 200, 0.4);
  font-size: 14px;
  z-index: 100;
  color: #fff;
  line-height: 40px;
  padding: 0 10px;
}

.video-item-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #2696c2;
  font-size: 16px;
}
</style>